<template>
  <v-app id="app">
    <component :is="$route.meta.layout">
      <RouterView />
    </component>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  components: {
    NavBar,
  },
};
</script>
