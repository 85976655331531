<template>
  <v-main>
    <h1 class="text-center">{{ $store.getters.langData.result.title }}</h1>
    <p class="text-center">
      {{ $store.getters.langData.result.subtitle }}
    </p>
    <div class="d-flex content">
      <v-container class="sidebar">
        <v-card class="mx-auto">
          <v-card-title class="font-weight-bold"
            >{{ $store.getters.langData.result.results }}
          </v-card-title>
          <v-list v-if="data.length > 0" :opened="[currentDepartment]">
            <v-list-group
              v-for="(department, i) in data"
              :key="i"
              :value="department.name"
            >
              <template v-slot:activator="{ props }">
                <v-list-item
                  v-bind="props"
                  :title="department.name"
                ></v-list-item>
              </template>
              <v-list-item
                v-for="(speciality, j) in department.specialities"
                :key="j"
                :title="speciality.name"
                :value="speciality"
                :active="speciality.name === currentSpeciality.name"
                @click="selectSpeciality(speciality)"
              ></v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
        <v-card class="mx-auto mt-3">
          <v-card-title class="font-weight-bold"
            >{{ $store.getters.langData.result.getResults }}
          </v-card-title>
          <v-form ref="form" lazy-validation @submit.prevent="submit()">
            <v-text-field
              class="mx-3 mt-2"
              v-model="email"
              :label="$store.getters.langData.result.email"
              :rules="[
                (v) => !!v || 'E-mail is required',
                (v) =>
                  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    v
                  ) || 'E-mail must be valid',
              ]"
            >
            </v-text-field>
            <v-btn class="mx-3 mb-2" type="submit">
              {{ $store.getters.langData.result.emailSubmit }}
            </v-btn>
          </v-form>
        </v-card>
      </v-container>
      <v-container>
        <v-card
          v-if="currentSpeciality.name != null"
          max-width="800px"
          width="100%"
        >
          <v-img
            height="250px"
            :src="this.currentSpeciality.banner_url"
            cover
          ></v-img>
          <v-card-title class="font-weight-bold">{{
            currentSpeciality.name
          }}</v-card-title>
          <v-card-text>
            <div v-html="currentSpeciality.text"></div>
          </v-card-text>
        </v-card>
      </v-container>
    </div>
  </v-main>
</template>
<script>
import config from "@/helpers/api";

export default {
  data() {
    return {
      email: null,
      currentSpeciality: {
        banner: null,
        name: null,
        text: null,
      },
      data: [],
    };
  },
  computed: {
    currentDepartment() {
      return (
        this.data.find((department) =>
          department?.specialities.find(
            (speciality) => speciality.name === this.currentSpeciality.name
          )
        )?.name || null
      );
    },
  },
  async mounted() {
    var answers;
    try {
      answers = JSON.parse(atob(this.$route.query.data));
    } catch (e) {
      this.$router.push("/");
      return;
    }

    await fetch(`${config.baseURL}/test_result`, {
      method: "POST",
      headers: config.headers,
      body: JSON.stringify({
        test_answers: answers,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        this.data = data.departments;
        this.currentSpeciality = data.departments[0].specialities[0];
      })
      .catch((error) => {
        console.error("Error:", error);
        this.$router.push("/");
      });
  },
  methods: {
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      await this.$recaptchaLoaded();
      const token = await this.$recaptcha("login");
      await fetch(`${config.baseURL}/send_result`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify({
          link: window.location.href,
          email: this.email,
          "g-recaptcha-response": token,
        }),
      });
    },
    selectSpeciality(speciality) {
      this.currentSpeciality = speciality;
    },
  },
};
</script>

<style scoped>
.v-main {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
}

.v-card {
  padding: 0px !important;
}

.v-card-title {
  white-space: unset;
}

.sidebar {
  width: 40%;
}

h1 {
  font-size: 50px;
  font-weight: 300;
}

p {
  font-size: 20px;
}

@media only screen and (width <= 1240px) {
  .v-main {
    width: 100%;
  }
}

@media only screen and (width <= 960px) {
  .v-main {
    width: 100%;
  }

  .content {
    flex-direction: column;
    width: 100%;
  }

  .sidebar {
    width: 100%;
  }

  h1 {
    font-size: 32px;
    width: 100%;
    font-weight: 500;
    margin-top: 50px;
  }

  p {
    font-size: 15px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
