<template>
  <v-container class="form-container rounded-lg">
    <h1>Test create</h1>
    <TestCreateForm :fields="fields" name="tests" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
import TestCreateForm from "@/Admin/components/TestCreateForm.vue";
export default {
  name: "TestCreateView",
  components: { TestCreateForm },
  data() {
    return {
      fields: {
        name: {
          component: markRaw(TextField),
          data: {
            label: "Name",
            placeholder: "Enter test name",
            name: "name",
            rules: ruleSetGen.text("Enter a valid department name", true, 3),
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
