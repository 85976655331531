import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "@/styles/main.scss";

import { createStore } from "vuex";

import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import { VueReCaptcha } from "vue-recaptcha-v3";

const vuetify = createVuetify({
  components,
  directives,
  variables: {
    treeShake: true,
    customVariables: ["/styles/main.scss"],
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#efbc1c",
        },
      },
    },
  },
});

const store = createStore({
  state() {
    return {
      langData: JSON.parse(localStorage.getItem("language")) || {},
    };
  },
  getters: {
    langData(state) {
      return state.langData;
    },
  },
  mutations: {
    updateLangData(state, newData) {
      state.langData = newData;
    },
  },
  actions: {
    editLangData(state, newData) {
      store.commit("updateLangData", newData);
    },
  },
});

createApp(App)
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITEKEY })
  .use(store)
  .use(vuetify)
  .use(router)
  .mount("#app");
