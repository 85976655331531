<template>
  <div class="content">
    <h1>Tests</h1>
    <v-select
      v-model="selectedId"
      label="Active test"
      placeholder="Select active test"
      :items="tests"
      item-title="name"
      item-value="id"
      @update:model-value="input"
    ></v-select>
    <TableContent :table-info="userTableInfo" name="tests"></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import EditAction from "@/Admin/components/form_actions/EditAction.vue";
import DeleteAction from "@/Admin/components/form_actions/DeleteAction.vue";
import config from "@/helpers/api";

export default {
  name: "TestListView",
  components: { TableContent },
  data: () => ({
    selectedId: null,
    tests: [],
    userTableInfo: {
      headers: [
        {
          title: "id",
          align: "start",
          sortable: false,
          key: "id",
        },
        {
          title: "Name",
          align: "start",
          key: "name",
        },
        {
          title: "Actions",
          align: "start",
          key: "actions",
        },
      ],
      actions: [EditAction, DeleteAction],
    },
  }),
  methods: {
    input() {
      fetch(`${config.baseURL}/settings/active_test`, {
        method: "PUT",
        headers: config.headers,
        body: JSON.stringify({
          value: this.selectedId,
        }),
      }).then(() => {
        alert("Changed active test");
      });
    },
  },
  mounted() {
    fetch(`${config.baseURL}/settings/active_test`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.selectedId = response.value;
      });
    fetch(`${config.baseURL}/test_list`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.tests = response;
      });
  },
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
