<template>
  <div>
    <v-toolbar :height="navbarHeight" class="navbar">
      <div class="logo-container" @click="$router.push('/')">
        <v-img :src="logo" />
      </div>
      <a href="https://career.rvt.lv/" class="ml-5"
        ><v-img
          :src="latvia"
          width="50px"
          height="25px"
          style="border: 1px solid #3d3d3d; box-sizing: content-box"
      /></a>
      <a href="https://est-career.rvt.lv/" class="ml-1"
        ><v-img
          :src="estonia"
          width="50px"
          height="25px"
          style="border: 1px solid #3d3d3d; box-sizing: content-box"
      /></a>
      <a href="https://mk-career.rvt.lv/" class="ml-1"
        ><v-img
          :src="macedonia"
          width="50px"
          height="25px"
          style="border: 1px solid #3d3d3d; box-sizing: content-box"
      /></a>
      <v-spacer></v-spacer>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      logo: new URL("/src/assets/logo.svg", import.meta.url).href,
      latvia: new URL("/src/assets/latvia.png", import.meta.url).href,
      estonia: new URL("/src/assets/estonia.png", import.meta.url).href,
      macedonia: new URL("/src/assets/macedonia.png", import.meta.url).href,
    };
  },
  computed: {
    navbarHeight() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return 72;
        case "sm":
          return 64;
        case "md":
          return 72;
        case "lg":
          return 80;
        case "xl":
          return 86;
        default:
          return 72;
      }
    },
  },
};
</script>

<style scoped>
.logo-container {
  height: 100%;
  aspect-ratio: 150 / 101;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.navbar {
  max-width: 1600px !important;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 30px;
  padding-inline: 35px;
  background-color: transparent;
}

@media only screen and (max-width: 960px) {
  .navbar {
    padding-top: 20px;
    padding-inline: 25px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    padding-top: 10px;
    padding-inline: 20px;
  }
}
</style>
