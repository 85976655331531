const config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }),
  formDataHeaders: new Headers({
    Accept: "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  }),
  // token: localStorage.getItem("token") || null,
};
export default config;
