<template>
  <v-container class="form-container rounded-lg">
    <h1>Edit test</h1>
    <TestEditForm :fields="fields" name="tests" :questions="questions" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
import config from "@/helpers/api";
import TestEditForm from "@/Admin/components/TestEditForm.vue";
export default {
  name: "TestEditView",
  components: { TestEditForm },
  data() {
    return {
      questions: null,
      fields: {
        name: {
          component: markRaw(TextField),
          data: {
            label: "Name",
            placeholder: "Enter test name",
            name: "name",
            rules: ruleSetGen.text("Enter a valid department name", true, 3),
          },
        },
      },
    };
  },
  async created() {
    await fetch(`${config.baseURL}/tests/${this.$route.params.id}`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        for (const key in this.fields) {
          this.fields[key].data.value = response.data[key];
        }
        this.questions = response.data.questions;
        this.questions = this.questions.map((question) => {
          if (question.banner_url === "") {
            question.banner_url = null;
          }
          return question;
        });
      });
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
