<template>
  <v-container class="form-container rounded-lg">
    <h1>New key word</h1>
    <CreateForm :fields="fields" name="keyWords" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import CreateForm from "@/Admin/components/CreateForm.vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSetGen } from "@/Admin/helpers/rules";
export default {
  name: "KeywordCreateView",
  components: { CreateForm },
  data() {
    return {
      fields: {
        text: {
          component: markRaw(TextField),
          data: {
            label: "Name",
            placeholder: "Enter key word name",
            name: "text",
            rules: ruleSetGen.text("Enter a valid key word name", true, 3),
          },
        },
        description: {
          component: markRaw(TextField),
          data: {
            label: "Description",
            placeholder: "Enter description",
            name: "description",
            rules: ruleSetGen.text("Enter a valid key word name", true, 3),
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
