<template>
  <v-form ref="form" class="w-100 d-flex flex-column mt-5" @submit.prevent>
    <!--  Success message  -->
    <v-alert
      v-show="success"
      type="success"
      class="mb-5"
      text="Test has been modified"
    ></v-alert>

    <!--  Failure message  -->
    <v-alert
      v-show="failure"
      type="error"
      class="mb-5"
      :text="failureMessage"
    ></v-alert>

    <!--  Form content  -->
    <component
      :is="field.component"
      v-for="(field, index) in fields"
      :key="index"
      :ref="field.data.name"
      v-model="field.data.value"
      :data="field.data"
      class="component"
    >
    </component>

    <!-- Questions -->
    <v-expansion-panels v-if="questions" variant="accordion" class="mt-4">
      <v-expansion-panel
        v-for="(question, index) in [...questions, ...newQuestions]"
        :key="index"
      >
        <v-expansion-panel-title v-if="!question.hidden">
          {{ index + 1 }}. {{ question.text }}
          {{ question.multi ? "(Multi answer)" : "(Single answer)" }}
        </v-expansion-panel-title>
        <v-expansion-panel-text v-if="!question.hidden">
          <v-form>
            <div class="mb-5 d-flex ga-1">
              <v-btn
                color="red"
                text="Remove question"
                @click="removeQuestion(question)"
              ></v-btn>
              <v-btn
                color="primary"
                :text="
                  question.multi
                    ? 'Change to single answer'
                    : 'Change to multi answer'
                "
                @click="toggleMultiStatus(question)"
              ></v-btn>
            </div>
            <v-img
              width="300px"
              height="200px"
              class="ml-auto mr-auto"
              v-if="
                typeof question.banner_url === 'string' &&
                question.banner_url.includes('http')
              "
              :src="question.banner_url"
            ></v-img>
            <v-file-input
              label="Image url"
              variant="underlined"
              v-model="question.banner_url"
            ></v-file-input>
            <v-text-field
              v-model="question.text"
              label="Question"
              placeholder="Enter question"
              required
              hide-details="auto"
              variant="underlined"
              :rules="questionRule"
            >
            </v-text-field>
            <v-container
              v-for="(answer, index1) in question.answers"
              :key="index1"
              class="d-flex align-center justify-start"
            >
              <v-container class="d-flex flex-column">
                <v-text-field
                  v-model="answer.text"
                  label="Answer"
                  placeholder="Enter answer"
                  required
                  :rules="answerRule"
                  hide-details="auto"
                  variant="underlined"
                >
                </v-text-field>
                <v-autocomplete
                  chips
                  v-model="answer.keywords"
                  label="Keywords"
                  variant="underlined"
                  class="ml-5"
                  :items="keywords"
                  item-title="text"
                  item-value="id"
                  multiple
                ></v-autocomplete>
              </v-container>
              <v-btn
                color="red"
                icon="mdi-minus"
                style="width: 30px; height: 30px; font-size: 12px"
                @click="removeAnswer(question, answer.id)"
              ></v-btn>
            </v-container>
            <v-btn
              color="primary"
              text="Add answer"
              @click="addAnswer(question)"
            ></v-btn>
          </v-form>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      class="d-flex w-100 align-center justify-center mt-4"
      style="gap: 20px"
    >
      <v-btn
        color="primary"
        text="Add single answer question"
        @click="addQuestion()"
      ></v-btn>
      <v-btn
        color="primary"
        text="Add multiple answer question"
        @click="addQuestion(true)"
      ></v-btn>
    </div>
    <!-- Submit button -->
    <v-btn
      type="submit"
      block
      class="mt-5"
      text="Submit"
      @click="submit"
    ></v-btn>
  </v-form>
</template>

<script>
import { ruleSetGen } from "@/Admin/helpers/rules";
import config from "@/helpers/api";

export default {
  props: {
    questions: {
      type: Object,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      keywords: null,
      answerRule: ruleSetGen.text("Enter a valid answer", true, 2),
      questionRule: ruleSetGen.text("Enter a valid question", true, 2),
      loading: false,
      success: false,
      failure: false,
      failureMessage: null,
      correctAnswers: [],
      newQuestions: [],
    };
  },
  watch: {
    // questions(value) {
    //   value.map((question, index) => {
    //     this.correctAnswers[index] = question.answers.filter(
    //       (answer) => answer.correct === true
    //     )[0].id;
    //     return question;
    //   });
    // },
  },
  async created() {
    await fetch(`${config.baseURL}/keyWords`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.keywords = response.data;
      });
  },
  methods: {
    addQuestion(multi = false) {
      this.newQuestions.push({
        title: "",
        banner_url: "",
        id: Math.floor(Math.random() * 10000000000) + 10000000,
        multi: multi,
        answers: [
          {
            text: "",
            keywords: [],
            id: Math.floor(Math.random() * 10000000000) + 10000000,
          },
        ],
      });
    },
    removeQuestion(question) {
      question.hidden = true;
    },
    changeCorrectAnswer(question, answerId) {
      question.answers = question.answers.map((answer) => {
        answer.correct = false;
        if (answer.id === answerId) {
          answer.correct = true;
        }
        return answer;
      });
    },
    addAnswer(question) {
      question.answers.push({
        correct: false,
        title: "",
        keywords: [],
        id: Math.floor(Math.random() * 10000000000) + 10000000,
      });
    },
    removeAnswer(question, answerId) {
      question.answers = question.answers.filter(
        (answer) => answer.id !== answerId
      );
    },
    async readFileAsDataURL(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result);
        fileReader.readAsDataURL(file);
      });
      return result_base64;
    },
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      const data = {};
      for (const key in this.fields) {
        const field = this.$refs[this.fields[key].data.name][0];
        if (field.value === null || field.value === undefined) continue;
        if (typeof field.value === "object") {
          if (field.value[0]) {
            data[field.data.name] = field.value[0];
          } else {
            data[field.data.name] = field.value.id;
          }
        } else {
          data[field.data.name] = field.value;
        }
      }

      data.questions = [...this.questions, ...this.newQuestions].filter(
        (question) => question.hidden !== true
      );

      for (const key in data.questions) {
        if (
          typeof data.questions[key].banner_url === "object" &&
          data.questions[key].banner_url instanceof File
        ) {
          data.questions[key].banner_url = await this.readFileAsDataURL(
            data.questions[key].banner_url ?? data.questions[key].banner_url[0]
          );
        }
      }

      await fetch(`${config.baseURL}/${this.name}/${this.$route.params.id}`, {
        method: "PUT",
        headers: config.headers,
        body: JSON.stringify(data),
      })
        .then(async (response) => {
          if (response.ok) {
            this.success = true;
            setTimeout(() => {
              this.$router.push(`/admin/${this.name}/list`);
            }, 1000);
          } else {
            const body = await response.json();
            this.failureMessage = body.message || body.errors[0];
            this.failure = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleMultiStatus(question) {
      question.multi = !question.multi;
    },
  },
};
</script>

<style scoped>
form {
  gap: 5px;
  padding: 10px;
}

.v-selection-control {
  flex: unset !important;
}
</style>
