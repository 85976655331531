<template>
  <div>
    <v-tooltip v-if="allValuesTruthy" text="All values assigned" location="top">
      <template v-slot:activator="{ props }">
        <v-icon
          color="green"
          icon="mdi-checkbox-marked-circle"
          v-bind="props"
        ></v-icon>
      </template>
    </v-tooltip>

    <v-tooltip
      v-else
      :text="warningMessages"
      style="white-space: pre-wrap"
      location="top"
    >
      <template v-slot:activator="{ props }">
        <v-icon color="orange" icon="mdi-alert" v-bind="props"></v-icon>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "TableWarnings",
  props: {
    item: {
      type: Object,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
  },
  computed: {
    warningMessages() {
      let message = "";
      for (const field of this.fields) {
        if (!this.item[field.key]) {
          message += `${field.name} is not assigned\n`;
        }
      }
      return message;
    },
    allValuesTruthy() {
      for (const field of this.fields) {
        if (!this.item[field.key]) return false;
      }
      return true;
    },
  },
};
</script>

<style scoped></style>
