<template>
  <v-main>
    <NavBar />
    <slot />
  </v-main>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import config from "@/helpers/api";
// import language from "@/helpers/lang";

export default {
  components: { NavBar },
  beforeCreate() {
    fetch(`${config.baseURL}/current_language`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        document.title = response.home.tabTitle;
        this.$store.dispatch("editLangData", response);
        localStorage.setItem("language", JSON.stringify(response));
        this.$forceUpdate();
      });
  },
};
</script>
