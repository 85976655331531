<template>
  <v-main>
    <div class="d-flex flex-column justify-center align-center ga-6 mx-3 mt-10">
      <div>
        <h1
          class="text-md-h2 text-h4 text-center font-weight-medium mb-3 noto-sans"
        >
          {{ $store.getters.langData.home.title }}
        </h1>
        <p
          class="text-md-h5 text-h6 text-center font-weight-regular mx-4 noto-sans"
        >
          {{ $store.getters.langData.home.subtitle }}
        </p>
      </div>
      <v-btn
        class="text-center text-white"
        size="x-large"
        color="#FFBE0B"
        flat
        @click="$router.push('/quiz')"
      >
        {{ $store.getters.langData.home.startTest }}
      </v-btn>
    </div>
  </v-main>
</template>

<script>
export default {};
</script>
