<template>
  <div class="content">
    <h1>Language</h1>
    <TableContent :table-info="userTableInfo" name="language"></TableContent>
  </div>
</template>

<script>
import TableContent from "@/Admin/components/TableContent.vue";
import EditAction from "@/Admin/components/form_actions/EditAction.vue";

export default {
  name: "LanguageListView",
  components: { TableContent },
  data: () => ({
    userTableInfo: {
      headers: [
        {
          title: "Fields",
          align: "start",
          sortable: false,
          key: "field",
        },
        {
          title: "Value",
          align: "start",
          key: "value",
        },
        {
          title: "On page",
          align: "start",
          key: "page",
        },
        {
          title: "Actions",
          align: "start",
          key: "actions",
        },
      ],
      actions: [EditAction],
    },
  }),
};
</script>

<style scoped>
.content {
  width: 100%;
}
</style>
