<template>
  <v-form ref="form" class="w-100 d-flex flex-column mt-5" @submit.prevent>
    <!--  Success message  -->
    <v-alert
      v-show="success"
      type="success"
      class="mb-5"
      text="Added new row"
    ></v-alert>

    <!--  Failure message  -->
    <v-alert
      v-show="failure"
      type="error"
      class="mb-5"
      :text="message"
      style="white-space: pre-line"
    ></v-alert>

    <!--  Form content  -->
    <component
      :is="field.component"
      v-for="(field, index) in fields"
      :key="index"
      :ref="field.data.name"
      v-model="field.data.value"
      :data="field.data"
      class="component"
    >
    </component>

    <!-- Submit button -->
    <v-btn
      type="submit"
      block
      class="mt-5"
      text="Create"
      @click="submit"
    ></v-btn>
  </v-form>
</template>

<script>
import config from "@/helpers/api";

export default {
  props: {
    fields: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      failure: false,
      message: "There is an error",
    };
  },
  methods: {
    async submit() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      this.loading = true;
      this.failure = false;
      this.message = "There is an error";
      const formData = new FormData();
      for (const key in this.fields) {
        const field = this.$refs[this.fields[key].data.name][0];
        if (field.value === null || field.value === undefined) continue;
        if (typeof field.value === "object") {
          if (field.value[1]) {
            for (const fieldIndex in field.value) {
              formData.append(
                `${field.data.name}[${fieldIndex}]`,
                field.value[fieldIndex].id
              );
            }
          } else if (field.value[0]) {
            if (field.data.name === "keywords") {
              formData.append(`${field.data.name}[0]`, field.value[0].id);
            } else {
              formData.append(field.data.name, field.value[0]);
            }
          } else {
            formData.append(field.data.name, field.value.id);
          }
        } else {
          formData.append(field.data.name, field.value);
        }
      }
      await fetch(`${config.baseURL}/${this.name}`, {
        method: "POST",
        headers: config.formDataHeaders,
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            this.success = true;
            setTimeout(() => {
              this.$router.push(`/admin/${this.name}/list`);
            }, 1000);
          } else {
            return Promise.reject(response);
          }
        })
        .finally(() => {
          this.loading = false;
        })
        .catch((response) => {
          response.json().then((json) => {
            if (!json.errors) return;
            this.message = "";
            for (const key in json.errors) {
              this.message += json.errors[key][0] + "\n";
            }
          });
          this.failure = true;
        });
    },
  },
};
</script>

<style scoped>
form {
  gap: 5px;
  padding: 10px;
}
</style>
