<template>
  <div class="content">
    <h1>Main panel</h1>
    <p>Select something from sidebar</p>
    <div class="d-flex">
      <InfoCard
        v-for="(statistic, index) in statistics"
        :key="index"
        :icon="statistic.icon"
        :title="statistic.display_name"
        :value="statistic.value"
      />
    </div>
  </div>
</template>

<script>
import config from "@/helpers/api";
import InfoCard from "@/Admin/components/InfoCard.vue";
export default {
  name: "MainView",
  components: { InfoCard },
  data() {
    return {
      statistics: [],
    };
  },
  mounted() {
    fetch(`${config.baseURL}/statistics`, {
      method: "GET",
      headers: config.headers,
      body: JSON.stringify(this.form),
    })
      .then((response) => response.json())
      .then((response) => {
        this.statistics = response;
      });
  },
};
</script>

<style scoped>
.content {
  width: 100%;
}

.info-card-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.info-container {
  margin: 20px 0;
}
</style>
