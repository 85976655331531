<template>
  <v-container class="form-container rounded-lg">
    <h1>Edit speciality</h1>
    <EditForm :fields="fields" name="specialities" />
  </v-container>
</template>

<script>
import { markRaw } from "vue";
import TextField from "@/Admin/components/form/TextField.vue";

import { ruleSet, ruleSetGen } from "@/Admin/helpers/rules";
import EditForm from "@/Admin/components/EditForm.vue";
import config from "@/helpers/api";
import SelectField from "@/Admin/components/form/SelectField.vue";
import TextEditorField from "@/Admin/components/form/TextEditorField.vue";
export default {
  name: "SpecialityEditView",
  components: { EditForm },
  data() {
    return {
      fields: {
        name: {
          component: markRaw(TextField),
          data: {
            label: "Name",
            placeholder: "Enter speciality name",
            name: "name",
            rules: ruleSetGen.text("Enter a valid speciality name", true, 3),
          },
        },
        banner_url: {
          component: markRaw(TextField),
          data: {
            label: "Banner url",
            placeholder: "Enter banner url",
            name: "banner_url",
            rules: ruleSetGen.text("Enter a valid speciality name", true, 3),
          },
        },
        department_id: {
          component: markRaw(SelectField),
          data: {
            label: "Department",
            placeholder: "Select department",
            name: "department_id",
            items: [],
            itemTitle: "name",
            itemValue: "id",
          },
        },
        keywords: {
          component: markRaw(SelectField),
          data: {
            label: "Key words",
            placeholder: "Select key words",
            name: "keywords",
            items: [],
            multiple: true,
            itemTitle: "text",
            itemValue: "id",
            rules: [ruleSet.select],
          },
        },
        text: {
          component: markRaw(TextEditorField),
          data: {
            label: "Description",
            placeholder: "Enter description",
            name: "text",
            rules: ruleSetGen.text("Enter a valid description", true, 3),
          },
        },
      },
    };
  },
  async created() {
    await fetch(`${config.baseURL}/departments`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.fields.department_id.data.items = response.data;
      });

    await fetch(`${config.baseURL}/keyWords`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.fields.keywords.data.items = response.data;
      });

    await fetch(`${config.baseURL}/specialities/${this.$route.params.id}`, {
      method: "GET",
      headers: config.headers,
    })
      .then((response) => response.json())
      .then((response) => {
        for (const key in this.fields) {
          this.fields[key].data.value = response.data[key];
        }
      });
  },
};
</script>

<style scoped>
.form-container {
  width: 1000px;
  margin: auto;
  background-color: white;
  padding: 10px;
}
</style>
