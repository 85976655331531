<template>
  <v-main>
    <h1 class="text-center">Login</h1>
    <v-container class="container">
      <v-card class="pa-5">
        <v-form fast-fail @submit.prevent="login" ref="form" lazy-validation>
          <v-text-field
            v-model="form.username"
            label="Username"
            :rules="rules"
          ></v-text-field>
          <v-text-field
            v-model="form.password"
            label="Password"
            :rules="rules"
            type="password"
          ></v-text-field>
          <v-btn type="submit" color="primary" block class="mt-2"
            >Sign in</v-btn
          >
        </v-form>
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import config from "@/helpers/api";

export default {
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      rules: [
        (value) => {
          if (value) return true;
          return "Field is required";
        },
      ],
    };
  },
  methods: {
    async login() {
      const { valid } = await this.$refs.form.validate();
      if (!valid) return;
      await fetch(`${config.baseURL}/login`, {
        method: "POST",
        headers: config.headers,
        body: JSON.stringify(this.form),
      }).then(async (response) => {
        const responseBody = await response.json();
        if (!response.ok) {
          alert("Failed to login");
        } else {
          const token = responseBody.access_token;
          localStorage.removeItem("token");
          localStorage.setItem("token", token);
          config.headers = new Headers({
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          });
          config.formDataHeaders = new Headers({
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          });
          setTimeout(() => {
            this.$router.push("/admin");
          }, 1000);
        }
      });
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 500px;
}
</style>
